<template>
  <div class="gallery-grid">
    <div
      class="gallery-block"
      v-for="index of sortedImages.blocks"
      :key="index"
    >
      <img
        v-if="sortedImages.horizontal[index]"
        :src="sortedImages.horizontal[index]"
        :alt="sortedImages.horizontal[index]"
        class="gallery-block--double"
      />
      <img
        v-if="sortedImages.vertical[index * 2]"
        :src="sortedImages.vertical[index * 2]"
        :alt="sortedImages.vertical[index * 2]"
      />
      <img
        v-if="sortedImages.vertical[index * 2 + 1]"
        :src="sortedImages.vertical[index * 2 + 1]"
        :alt="sortedImages.vertical[index * 2 + 1]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    local: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chunkSize: 3,
      sortedImages: {
        vertical: [],
        horizontal: [],
        blocks: [],
      },
    };
  },
  computed: {},
  created() {
    this.sortImages();
  },
  methods: {
    async sortImages() {
      const numOfChunks = this.images.length / this.chunkSize;
      for (let i = 0; i < numOfChunks; i++) {
        const arrayChunk = this.images.slice(
          i * this.chunkSize,
          Math.min(i * this.chunkSize + this.chunkSize, this.images.length)
        );

        await this.parseImagesChunk(arrayChunk);
        this.sortedImages.blocks = Math.ceil(
          Math.max(
            this.sortedImages.horizontal.length,
            this.sortedImages.vertical.length / 2
          )
        );
      }
    },
    async parseImagesChunk(images) {
      const horizontalImages = [];
      const verticalImages = [];

      for (const image of images) {
        let dimensions = await this.sizeOf(image);
        if (dimensions.width > dimensions.height) {
          horizontalImages.push(image);
        } else {
          verticalImages.push(image);
        }
      }
      this.sortedImages.horizontal = [
        ...this.sortedImages.horizontal,
        ...horizontalImages,
      ];
      this.sortedImages.vertical = [
        ...this.sortedImages.vertical,
        ...verticalImages,
      ];
    },
    async sizeOf(imageSrc) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve({ height: img.height, width: img.width });
        img.onerror = reject;
        if (this.local) {
          img.src = require(`../assets/pictures/${imageSrc}`);
        } else {
          img.src = imageSrc;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-block {
  display: grid;
  grid-template-rows: 1fr;
  gap: 24px;
  margin-bottom: 24px;

  &--double {
    @media (min-width: 1024px) {
      grid-column: span 2;
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
