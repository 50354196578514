<template>
  <section
    class="pt-topmenu xl:pt-0"
    v-if="event.nombre"
    :class="{
      'bg-pink': pluralType === 'bodas',
      'bg-red': pluralType === 'eventos',
      'bg-gold': pluralType === 'fiestas',

      'text-white white-section': isDark,
    }"
  >
    <divider class="hidden xl:block" :white="isDark" />

    <!-- Header -->
    <div
      class="mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding my-4 xl:mt-0 xl:mb-10 flex items-start"
    >
      <router-link :to="{ name: pluralType }">
        <img
          :src="
            require(`../assets/img/${
              isDark ? 'back-white.svg' : 'back-black.svg'
            }`)
          "
          alt="back"
          class="mr-6"
          style="width: 24px; height: 24px"
        />
      </router-link>
      <router-link
        :to="{ name: pluralType }"
        class="underlined-hover text-sm mr-6 capitalize"
        :class="{ 'underlined-hover--white': isDark }"
      >
        {{ pluralType }}
      </router-link>
      <span class="text-sm underlined" :class="{ 'underlined--white': isDark }"
        >{{ event.nombre }}
      </span>
    </div>

    <div
      class="flex flex-col md:flex-row md:justify-between md:items-end mb-xlpadding mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding"
    >
      <div class="md:w-half md:pr-8">
        <h1
          class="flex-1 font-title italic text-bigtitle lg:text-lgtitle xl:text-xltitle leading-bigtitle lg:leading-lgtitle xl:leading-xltitle lg:max-w-block"
        >
          {{ event.nombre }}
        </h1>
      </div>

      <div class="flex-1 md:pl-8 mt-5 xl:mt-0 md:w-half">
        <div class="mb-4">¿Qué hemos hecho?</div>
        <div class="font-medium text-h2 leading-h2 mt-4">
          {{ event.que }}
        </div>
      </div>
    </div>

    <!-- Cover image -->
    <div
      style="padding: 56.25% 0 0 0; position: relative"
      class="event-details__mainPicture"
      v-if="event.videoPrincipal"
    >
      <iframe
        :src="event.videoPrincipal"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
    <div
      v-else
      :style="{ backgroundImage: `url(${event.fotoPrincipal}` }"
      class="event-details__mainPicture"
    />

    <gallery
      :images="detailsPictures"
      class="mx-padding my-xlpadding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding"
    />

    <div
      class="mx-padding my-xlpadding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding"
      v-if="event.participantes.length > 0"
    >
      <h2 class="font-medium text-h2 leading-h2">Lo han hecho posible:</h2>
      <div
        class="participants grid gap-8 md:grid-cols-2 lg:grid-cols-3 mt-padding"
      >
        <div
          v-for="participant in event.participantes"
          :key="participant.title"
        >
          <span>{{ participant.que }}:</span><br />
          <a
            :href="participant.link1Link"
            target="_new"
            class="font-medium underline"
            >@{{ participant.link1Nombre }}</a
          ><br />
          <a :href="participant.link2Link" target="_new" class="font-medium"
            >☞ <span class="underline">{{ participant.link2Nombre }}</span></a
          >
        </div>
      </div>
    </div>

    <!-- Contact -->
    <divider :white="isDark" />
    <contact-section />

    <!-- Footer -->
    <JFooter />
    <divider :white="isDark" />
  </section>
</template>

<script>
import Divider from "../components/Divider";
import ContactSection from "../components/ContactSection";
import JFooter from "../components/Footer";
import Gallery from "../components/Gallery";
import CMSService from "@/services/CMSService";

export default {
  name: "EventDetails",
  components: {
    Divider,
    ContactSection,
    JFooter,
    Gallery,
  },
  data() {
    return {
      hover: null,
      event: {},
    };
  },
  async created() {
    let event = this.$route.params.event;
    if (event) {
      event = JSON.parse(event);
    } else {
      event = await CMSService.details({
        slug: this.$route.params.project,
        type: this.$route.meta.type,
      });
    }

    this.event = event;
  },
  computed: {
    pluralType() {
      return this.$route.meta.type + "s";
    },
    isDark() {
      return this.pluralType === "eventos";
    },
    detailsPictures() {
      return this.event.fotos;
    },
  },
};
</script>

<style lang="scss" scoped>
.event-details {
  &__mainPicture {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 380px;

    @media (min-width: 727px) {
      height: 464px;
    }

    @media (min-width: 1023px) {
      height: 580px;
    }
  }
}
</style>
